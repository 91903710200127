<template>
<div class="data-train">
  <div class="data-train-cont">
    <div style="min-height: 100%">
      <div class="data-train-cont-l">
        <div v-if="!infoLoading">
          <div class="data-index">
            <p>{{curIndex + 1}}/{{answerCardList[curTypeIndex].list.length}}</p>
            <p class="blue-tag">{{ typesObj[dataInfo.datasetData.type] }}</p>
          </div>
          <div class="data-info">
            <Richtxt :value="dataInfo?.datasetData?.name" :isInline="true" :disabled="true"></Richtxt>
            <div class="data-info-options">
              <div :class="{greenC:dataInfo.userData.is_right == '1' && item.select,redC:dataInfo.userData.is_right == '0' && item.select,active:item.select }" v-for="item in dataInfo.datasetDataOptions" :key="item.id"  @click="selectAnswer(item)">
                <template v-if="dataInfo.datasetData.type == 1  || dataInfo.datasetData.type == 3">
                  <div style="width: 19px;height: 19px;" v-if="dataInfo?.userData?.answer">
                    <img v-if="item.select && dataInfo.userData.is_right == '1'" src="../../../../assets/images/common/dxz.png" width="19" height="19" />
                    <img v-if="item.select && dataInfo.userData.is_right == '0'" src="../../../../assets/images/common/dx.png" width="19" height="19" />
                    <img v-if="!item.select"  src="../../../../assets/images/common/wxz.png" width="19" height="19" />
                  </div>

                  <div style="width: 19px;height: 19px;" v-else>
                    <img v-if="item.select" src="../../../../assets/images/common/xz_radio.png" width="19" height="19" />
                    <img v-else  src="../../../../assets/images/common/wxz.png" width="19" height="19" />
                  </div>

                </template>
                <template v-else>
                  <div style="width: 19px;height: 19px;" v-if="dataInfo?.userData?.answer">
                    <img v-if="item.select && dataInfo.userData.is_right == '1'" src="../../../../assets/images/common/tgxz.png" width="19" height="19" />
                    <img v-if="item.select && dataInfo.userData.is_right == '0'" src="../../../../assets/images/common/hxz.png" width="19" height="19" />
                    <img v-if="!item.select"  src="../../../../assets/images/common/wx.png" width="19" height="19" />
                  </div>

                  <div style="width: 19px;height: 19px;" v-else>
                    <img v-if="item.select" src="../../../../assets/images/common/xz.png" width="19" height="19" />
                    <img v-else src="../../../../assets/images/common/wx.png" width="19" height="19" />
                  </div>

                </template>
                <Richtxt :value="item.name" :isInline="true" :disabled="true"></Richtxt>
              </div>
            </div>
            <div class="data-answer" v-if="dataInfo?.userData?.answer">
              <div>正确答案：<span>{{correctData.join(',')}}</span></div>
              <div>你的答案：<span>{{stuAnswer.join(',')}}</span></div>
            </div>
            <div class="data-explain" v-if="dataInfo?.userData?.answer && dataInfo.datasetData.explain">
              <p>解析</p>
              <Richtxt :value="dataInfo.datasetData.explain" :isInline="true" :disabled="true"></Richtxt>
            </div>

          </div>
          <div class="data-btn">
            <div :class="curTypeIndex ==0 && curIndex == 0 ? 'gray' : ''" @click="pre">上一题</div>
            <div :class="curTypeIndex >= answerCardList.length -1 && curIndex >= answerCardList[curTypeIndex].list.length - 1 ? 'gray' : ''" @click="next">下一题</div>
            <div @click="submitAnswer">提交</div>
          </div>
        </div>
        <div v-else v-loading="infoLoading" style="width: 100%;height: 100%;position: relative"></div>
      </div>
      <div class="data-train-cont-r">
        <div class="data-train-cont-r-top">
          <p>答题卡</p>
          <div class="data-train-cont-r-top-btn" @click="redoFn">重新开始</div>
        </div>
        <div class="data-train-cont-r-card" >
          <div v-for="(item,index) in answerCardList" :key="index">
            <p class="data-train-cont-r-card-type">{{ item.name }}（{{item.list.length}}题）</p>
            <div class="data-train-cont-r-card-list">
              <p v-for="(sItem,sIndex) in item.list" :class="{green: sItem.userData?.is_right == '1',red: sItem.userData?.is_right == '0',active:curIndex == sIndex && curTypeIndex == index}" @click="selectCardData(sIndex,index)">{{sIndex + 1}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="data-train-stat">
    答对:{{rightCount}}题<span>|</span>答错:{{haveFinished - rightCount}}题<span>|</span>未答:{{(dataList.length - haveFinished) < 0 ? 0 : dataList.length - haveFinished}}题<span>|</span>正确率:{{!rightCount ?  0 : Math.floor(rightCount / haveFinished * 100) }}%
  </div>
</div>
</template>

<script>
import Richtxt from "@/components/richtxt";
export default {
  name: "dataTrain",
  data(){
    return {
      curTypeIndex:0,
      curIndex:0,
      infoLoading:true,
      categoryId:'',
      answerCardLoading:false,
      answerCardList:{},
      trainUser:{},
      train:{},
      title:'',
      option:'',
      dataInfo:{},
      typesObj:{},
      correctData:[],
      stuAnswer:[],
      rightCount:0,
      haveFinished:0,
      dataList:[],
    }
  },
  components:{
    Richtxt
  },
  props:{
    nodeId:{
      type:String,
      default:''
    },
  },
  watch:{
    nodeId:{
      handler(newV){
        if(newV){
          this.$nextTick(()=>{
            this.getAnswerCard();
          })
        }
      },
      immediate:true
    }
  },
  created(){
    this.categoryId = this.$route.query.id || '';
  },
  methods:{
    getAnswerCard(){
      let params = {
        action:'testList',
        category_id:this.categoryId,
        data_rule: "10",
        data_type:"10",
        generate_id:this.nodeId,
        redo:this.redo,
      };


      this.answerCardLoading = true;
      this.api.attestation.trainExecute(params).then((res)=>{
        this.answerCardLoading = false;
        this.redo = '0';  //请求结束后还原
        this.dataList = res.data_list;
        if(res.data_list.length){
          this.answerCardList = [];
          for(let name in res.data_list_by_type){
            let obj = {
              name:res.types[name],
              id:name,
              list:res.data_list_by_type[name]
            }
            this.answerCardList.push(obj);
          }
          this.typesObj = res.types;
          this.trainUser = res.train_user;
          this.train = res.train;

          this.rightCount = Number(res.train_user.right_count);
          this.haveFinished = Number(res.train_user.have_finished);
          console.log(this.haveFinished,'this.haveFinishedthis.haveFinished')
          this.getInfo();
        }
      }).catch((err)=>{
        this.answerCardLoading = false;
      })
    },
    getInfo(){  //获取详情数据
      let params = {
        action:'testListInfo',
        group_id:this.answerCardList[this.curTypeIndex].list[this.curIndex].train_id,
        data_id:this.answerCardList[this.curTypeIndex].list[this.curIndex].data_id,
        train_user_id:this.trainUser.id,
        train_id:this.train.id,
      };
      this.infoLoading = true;
      this.api.attestation.trainExecute(params).then((res)=>{

        this.dataInfo = res[0];
        if(this.dataInfo.datasetData.type == 1 || this.dataInfo.datasetData.type == 2 || this.dataInfo.datasetData.type == 3) {
          if(this.answerCardList[this.curTypeIndex].list[this.curIndex].userData){
            let curAnswer = this.answerCardList[this.curTypeIndex].list[this.curIndex].userData.answer.split(',');

            this.dataInfo.datasetDataOptions.forEach((item)=>{
              let len = curAnswer.filter((sItem)=>{
                return item.id == sItem;
              }).length;
              if(len){
                this.$set(item,'select',true);
              }
            })
          }
        }
        console.log(this.dataInfo,'this.dataInfothis.dataInfo')
        // else if(this.dataInfo.datasetData.type == '9'){  //实操
        //   if(this.answerCardList[this.curTypeIndex].list[this.curIndex].userData.answer){
        //     let urlArr = this.answerCardList[this.curTypeIndex].list[this.curIndex].userData.answer.split('/');
        //     this.uploadName = urlArr[urlArr.length -1];
        //     this.uploadPath = this.answerCardList[this.curTypeIndex].list[this.curIndex].userData.answer;
        //   }else{
        //     this.uploadName ='';
        //     this.uploadPath = '';
        //   }
        // }else if(this.dataInfo.datasetData.type == '4'){  //填空
        //   let answer;
        //   if(this.answerCardList[this.curTypeIndex].list[this.curIndex].userData.answer){
        //     answer = this.answerCardList[this.curTypeIndex].list[this.curIndex].userData.answer.split(',');
        //   }
        //   this.dataInfo.datasetDataOptions.forEach((item,index)=>{
        //     if(answer[index]){
        //       this.$set(item,'myAnswer',answer[index]);
        //     }else{
        //       this.$set(item,'myAnswer','');
        //     }
        //   })
        // }else{  //8：简答、10：实验
        //   this.shortAnswer = this.answerCardList[this.curTypeIndex].list[this.curIndex]?.userData?.answer || '';
        // }
        this.formateData();
        this.startTime = this.getTime();
        this.infoLoading = false;
      }).catch((err)=>{
        this.infoLoading = false;
      })
    },
    selectCardData(sIndex,index){
      this.curIndex = sIndex;
      this.curTypeIndex = index;
      this.getInfo();
    },
    selectAnswer(data){ //选择答案
      if(this.dataInfo.userData.answer) return;
      if(this.dataInfo.datasetData.type == '1' || this.dataInfo.datasetData.type == '3'){  //1单选 3判断
        this.dataInfo.datasetDataOptions.forEach((item)=>{
          this.$set(item,'select',false);
        })
        this.$set(data,'select',true);
      }
      if(this.dataInfo.datasetData.type == '2'){  //2 多选
        this.$set(data,'select',!data.select);
      }
    },
    pre(){  //上一题
      if(this.infoLoading) return;
      // if(this.curIndex == 0 && this.curTypeIndex == 0) return;
      if(this.curIndex > 0){
        this.curIndex--;
        this.getInfo();
      }else{
        if(this.curTypeIndex > 0){
          this.curTypeIndex--;
          this.curIndex = this.answerCardList[this.curTypeIndex].list.length - 1;
          this.getInfo();
        }
      }
    },
    next(){ //下一题
      if(this.infoLoading) return;
      // if(this.curTypeIndex == this.answerCardList.length - 1 && this.curIndex == this.answerCardList[this.curTypeIndex].list.length - 1) return;
      if(this.curIndex < this.answerCardList[this.curTypeIndex].list.length - 1){
        this.curIndex++;
        this.getInfo();
      }else{
        if(this.curTypeIndex < this.answerCardList.length - 1){
          this.curTypeIndex++;
          this.curIndex = 0;
          this.getInfo();
        }
      }

    },
    submitAnswer(){
      this.commitAnswer(()=>{

      },'submit')
    },
    getTime(){
      let oDate = new Date();
      let time = Math.floor(oDate.getTime() / 1000);

      return time
    },
    submitPapers(){ //交卷
      this.commitAnswer();
      let unAnswer = [];
      this.answerCardList.forEach((item)=>{
        let arr = item.list.filter((sItem)=>{
          return !sItem.userData || (sItem.userData && !sItem.userData.answer)
        })
        if(arr.length){
          unAnswer.push(...arr)
        }
      })
      if(unAnswer.length){
        this.hasUnanswer = true;
      }else{
        this.hasUnanswer = false;
      }
      console.log(this.answerCardList,this.hasUnanswer,'this.answerCardListthis.answerCardList')
      this.confirmSubmitModal = true;
    },
    commitAnswer(fn,type){ //提交答案

      this.endTime = this.getTime();
      let date = this.endTime - this.startTime;

      let answer_data = [
        {
          id:this.answerCardList[this.curTypeIndex].list[this.curIndex].data_id,
          use_time:date
        }
      ];
      if(this.dataInfo.datasetDataOptions && this.dataInfo.datasetDataOptions.length){
        if(this.dataInfo.datasetData.type == '1' || this.dataInfo.datasetData.type == '2'|| this.dataInfo.datasetData.type == '3'){
          let answers = this.dataInfo.datasetDataOptions.filter((item)=>{
            return item.select;
          }).map((item)=>{
            return item.id;
          }).join(',');
          answer_data[0].answer = answers;
        }
        if(this.dataInfo.datasetData.type == '4'){
          let len = this.dataInfo.datasetDataOptions.filter((item)=>{
            return item.myAnswer;
          }).length;
          if(!len && !this.dataInfo.userData.id){
            return;
          }else{
            let answer = this.dataInfo.datasetDataOptions.map((item)=>{
              return item.myAnswer;
            })
            answer_data[0].answer = answer.join(',');
          }

        }
      }else{
        answer_data[0].answer = '';
      }

      if(this.dataInfo.datasetData.type == '9'){  //实操
        answer_data[0].answer = this.uploadPath;
      }
      if(this.dataInfo.datasetData.type == '8' || this.dataInfo.datasetData.type == '10'){  //简答、实验
        answer_data[0].answer = this.shortAnswer;
      }
      if(type == 'submit'){
        if(!answer_data[0].answer){
          this.$message({
            message: '请选择答案',
            type: 'warning'
          });
          return;
        }
      }
      let params = {
        action:'exerciseSubmit',
        use_time:date,
        answer_data:JSON.stringify(answer_data),
        train_user_id:this.trainUser.id,
        train_id:this.train.id,
      };
      this.commitLoading = true;
      this.api.attestation.trainExecute(params).then((res)=>{
        this.$set(this.answerCardList[this.curTypeIndex].list[this.curIndex],'userData',res.data_list[0].userData);
        this.$set(this.dataInfo,'userData',res.data_list[0].userData);
        this.formateData();
        if(res.data_list[0].userData.is_right == '1'){
          this.rightCount += 1;
        }
        this.haveFinished += 1;
        fn && fn();
        this.commitLoading = false;
      }).catch((err)=>{
        this.commitLoading = false;
      })
    },
    formateData(){
      let correctDataIndex = [];
      let stuAnswerIndex = [];
      let stuAnswer = this.dataInfo?.userData?.answer?.split(',') || [];
      this.dataInfo.datasetDataOptions.forEach((item,index)=>{
        if(item.is_correct == 1){
          correctDataIndex.push(index);
        }
        let arr = stuAnswer.filter((sItem)=>{
          return sItem == item.id;
        })
        if(arr.length){
          stuAnswerIndex.push(index);
        }
      })
      this.correctData =[];
      correctDataIndex.forEach((item)=>{
        this.correctData.push(String.fromCharCode(item + 65))
      })
      this.stuAnswer = [];
      stuAnswerIndex.forEach((item)=>{
        this.stuAnswer.push(String.fromCharCode(item + 65))
      })
    },
    redoFn(){
      this.redo = '1';
      this.curTypeIndex = 0;
      this.curIndex = 0;
      this.getAnswerCard();
    }
  }
}
</script>

<style scoped lang="scss">
.data-train{
  height: 100%;
  width: 100%;
  @include flex(column,flex-start,flex-start);
  .data-train-cont{
    flex: 1;
    height: 0;
    background: #FAFBFC;
    border: 1px solid $theme-border-gray;
    width: 100%;
    overflow-y: auto;
    >div{
      display: flex;
      justify-content: flex-start;
    }

    .data-train-cont-l{
      flex: 1;
      flex-shrink: 0;
      .data-index{
        margin: 26px 0 30px 30px;
        @include flex(row,flex-start,flex-start);
        font-size: 16px;
        font-weight: bold;
        .blue-tag{
          margin-left: 16px;
          @include btn(56px,23px,4px,12px,$theme-blue-bg,$theme-blue);
        }
      }
      .data-info{
        margin: 0 30px;
        line-height: 24px;
        .data-info-options{
          margin: 30px 0 20px 0;
          padding-bottom: 30px;
          border-bottom: 1px solid $theme-border-gray;
          >div{
            margin-bottom: 16px;
            @include flex(row,flex-start,center);
            cursor: pointer;
            >div{
              margin-right: 10px;
            }
            >img{
              margin-top: 2px;
              margin-right: 10px;
            }
          }
        }
        .data-answer{
          margin-bottom: 20px;
          @include flex(row,flex-start,flex-start);
          color: $theme-blue;
          >div{
            margin-right: 30px;
          }
        }
      }
      .data-btn{
        padding-bottom: 20px;
        margin: 20px 30px;
        @include flex(row,flex-start,flex-start);
        >div{
          margin-right: 14px;
          @include btn(70px,28px,4px,14px,#E1E9FF,#1664FF);
        }
        .gray{
          background: #E0E0E0;
          color: #999999;
        }
      }
    }
    .data-train-cont-r{
      width: 225px;
      background: #FFFFFF;
      border-left: 1px solid $theme-border-gray;
      .data-train-cont-r-top{
        padding: 26px 18px 0 18px;
        @include flex(row,space-between,center);
        font-size: 16px;
        font-weight: bold;
        .data-train-cont-r-top-btn{
          @include btn(80px,26px,13px,12px,#333333,);
        }
      }
      .data-train-cont-r-card{
        .data-train-cont-r-card-type{
          color: $theme-blue;
          margin: 20px 0 10px 20px;
        }
        .data-train-cont-r-card-list{
          margin-left: 20px;
          @include flex(row,flex-start,flex-start);
          flex-wrap: wrap;
          >p{
            margin:0 10px 10px 0;
            @include btn(30px,30px,4px,14px,#E0E0E0,#666666,);
          }
          .active{
            background: $theme-blue;
            color: #FFFFFF;
          }
        }
      }
    }
  }
  .data-train-stat{
    @include flex(row,flex-end,center);
    margin-top: 10px;
    font-size: 12px;
    color: #999999;
    width: 100%;
    >span{
      padding: 0 10px;
    }
  }
}
</style>
