<template>
<div class="practice">
  <div class="practice-l" ref="practiceL">
<!--    <div class="buy-times mt10">考试次数剩余 {{ info.cert_user && info.cert_user.exam_remain_num }} 次</div>-->
    <div class="buy-tit mt20">学习进度</div>
    <div class="study-progress">
      <div class="study-progress-chart">
        <div :style="{width: info.cert_user && info.cert_user.study_progress + '%'}"></div>
      </div>
      <div class="study-progress-val">{{info.cert_user && info.cert_user.study_progress}}%</div>
    </div>
    <div class="chapter" v-for="(item,index) in treeList" :key="item.id">
      <div class="chapter-tit" :class="posArr[0] == index ? 'active' : ''" @click="toggle(item)">
        <div>
          <span>{{index > 8 ? index + 1 : '0' + (index + 1)}}</span>
          <span>{{ item.node.name }}</span>
        </div>
        <img src="../../../../assets/images/attestation/x.png" width="17" height="10" :style="{transform:item.show ? 'rotate(180deg)' : 'rotate(0deg)'}" />
      </div>
      <div class="chapter-child" :style="{height: item.show ? item.children.length * 57 + 'px' : '0px'}">
        <div class="chapter-child-item" :class="posArr[1] == sIndex && posArr[0] == index ? 'active' : ''" v-for="(sItem,sIndex) in item.children" :key="sItem.id"  @click="startTrain(sItem,index,sIndex)">
          <div>{{ sItem.node.name }}</div>
          <!-- main_status 2 已完成  0待开始 1学习中 -->
          <div :class="sItem.progress.main_status == 2 ? 'chapter-child-item-gray-btn' : 'chapter-child-item-btn'" >{{sItem.progress.main_status == 2 ? '已完成' : sItem.progress.main_status == 0 ? '去学习' : '学习中'}}</div>
<!--          <div class="chapter-child-item-gray-btn">已完成</div>-->
        </div>
      </div>
    </div>
  </div>
  <div class="practice-r">
    <div class="practice-r-top">
      <div class="practice-r-tab">
        <div class="practice-r-tab-item" :class="curTabIndex == index ? 'active' : ''" v-for="(item,index) in tabList" :key="item.id" @click="changeTab(index)"><p>{{item.name}}</p></div>
      </div>
      <div class="practice-r-tit" v-if="posArr.length">{{ treeList[+posArr[0]] && treeList[+posArr[0]].node.name }}-{{ treeList[+posArr[0]] && treeList[+posArr[0]].children[+posArr[1]].node.name }}</div>
    </div>

    <div class="practice-r-cont" v-if="treeList.length">
      <component
          :is="tabList[curTabIndex].componentName"
          :videoPath="videoPath"
          :pptPath="pptPath"
          :wordPath="wordPath"
          :certUserId="info.cert_user && info.cert_user.id"
          :mapId="treeList[posArr[0]].children[posArr[1]].map_id"
          :nodeId="treeList[posArr[0]].children[posArr[1]].node_child_id"
          :account="info.account"
          :practical_operation_url="info.practical_operation_url"
          @update="update"
      ></component>
    </div>
  </div>
</div>
</template>

<script>
import VideoTrain from "@/views/attestation/components/train/videoTrain";
import DataTrain from "@/views/attestation/components/train/dataTrain";
import PptCheck from "@/views/attestation/components/train/pptCheck";
import WordCheck from "@/views/attestation/components/train/wordCheck";
export default {
  name: "practice.vue",
  data(){
    return{
      tabList:[
        {
          name:'视频学习',
          id:1,
          componentName:'VideoTrain'
        },
        {
          name:'课件PPT',
          id:3,
          componentName:'PptCheck'
        },
      ],
      curTabIndex:0,
      treeList:[],
      posArr:[0,0],
      videoPath:'',
      pptPath:'',
      pos:'',
      wordPath:'',
      categoryId:'',
      redo:'0',
      answerCardLoading:false,
    }
  },
  components:{
    VideoTrain,
    PptCheck,
    WordCheck,
    DataTrain
  },
  props:{
    info:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    list:{
      type:Array,
      default:()=>{
        return []
      }
    }
  },
  watch:{
    list:{
      handler(newV){
        if(newV.length){
          this.treeList = JSON.parse(JSON.stringify(newV));
          this.$nextTick(()=>{
            this.formatData();
          })
        }
      },
      immediate:true
    },
  },
  created(){
    this.pos = this.$route.query.pos || '';
    this.categoryId = this.$route.query.id || '';
    this.posArr = [0,0];
    if(this.pos){
      this.posArr = this.pos.split('-');
    }
  },
  methods:{
    changeTab(index){
      this.curTabIndex = index;
    },
    formatData(){
      // console.log(this.posArr,'this.posArrthis.posArr')
      this.treeList.forEach((item,index)=>{

        let posF = this.posArr[0] || 0;
        if(index == posF){
          this.$set(item,'show',true);
          let sIndex = +this.posArr[1] || 0;
          // this.curNode = item.children[sIndex];
          this.$nextTick(()=>{
            this.$refs.practiceL.scrollTop=document.getElementsByClassName('chapter')[index].offsetTop;
          })

        }else{
          this.$set(item,'show',false);
        }

      })

      this.getSouceDetail(this.treeList[this.posArr[0]].children[this.posArr[1]],1);
      this.getSouceDetail(this.treeList[this.posArr[0]].children[this.posArr[1]],2);
      this.getSouceDetail(this.treeList[this.posArr[0]].children[this.posArr[1]],3);

    },
    toggle(data){
      data.show = !data.show;
    },
    startTrain(data,index,sIndex){
      this.posArr = [];
      this.posArr.push(index);
      this.posArr.push(sIndex);
      this.curTabIndex = 0;
      this.getSouceDetail(data,1);
      this.getSouceDetail(data,2);
      this.getSouceDetail(data,3);
    },
    getSouceDetail(data,type){
      //type 1 视频 2 ppt
      let params = {
        map_id:data.map_id,
        node_id:data.node_child_id,
        type:type
      };
      this.api.attestation.sourceDetail(params).then((res)=>{
        if(type == 1){
          if(res.info){
            if(!res.info.path.indexOf('http')  == 0){ //不以http开头
              this.videoPath = process.env.VUE_APP_SHOW_URL +'?file=' + res.info.path;
            }else{
              this.videoPath = res.info.path;
            }
          }else{
            this.videoPath = '';
          }
        }else if(type == 2){
          if(res.info){
            if(!res.info.path.indexOf('http')  == 0){ //不以http开头
              this.pptPath = process.env.VUE_APP_SHOW_URL +'?file=' + res.info.path;
            }else{
              this.pptPath = res.info.path;
            }
          }else{
            this.pptPath = '';
          }

        }else{
          if(res.info){
            this.tabList = [
              {
                name:'视频学习',
                id:1,
                componentName:'VideoTrain'
              },
              {
                name:'课件PPT',
                id:3,
                componentName:'PptCheck'
              },
              {
                name:'实操手册',
                id:4,
                componentName:'WordCheck'
              },
            ];
            if(!res.info.path.indexOf('http')  == 0){ //不以http开头
              this.wordPath = process.env.VUE_APP_SHOW_URL +'?file=' + res.info.path;
            }else{
              this.wordPath = res.info.path;
            }

          }else{
            this.tabList = [
              {
                name:'视频学习',
                id:1,
                componentName:'VideoTrain'
              },
              // {
              //   name:'试题学习',
              //   id:2,
              //   componentName:'DataTrain'
              // },
              {
                name:'课件PPT',
                id:3,
                componentName:'PptCheck'
              },
            ];
            this.wordPath = '';
          }
          if(data.datas_count > 0){
            this.tabList.push({
              name:'试题学习',
              id:2,
              componentName:'DataTrain'
            })
          }
        }
      })
    },
    update(){
      this.$emit('update')
    }
  }
}
</script>

<style scoped lang="scss">
.practice{
  height: calc(100% - 24px);
  padding-top: 24px;
  //padding-bottom: 50px;
  @include flex(row,flex-start,flex-start);
  .practice-l{
    padding: 0 20px 0 20px;
    width: 300px;
    background: #FAFBFC;
    border-radius: 10px;
    height: 100%;
    overflow-y: auto;
    position: relative;

    .chapter{
      margin-bottom: 20px;
      .chapter-tit{
        padding: 0 20px;
        height: 50px;
        @include flex(row,space-between,center);
        font-size: 16px;
        font-weight: bold;
        >div{
          >span{
            padding-right: 5px;
          }
        }
        >img{
          transform:rotate(0deg);
          transition:transform 0.3s;
        }
      }
      .active{
        color:$theme-blue;
      }
      .chapter-child{
        height:0px;
        transition:height 0.5s;
        overflow: hidden;
        .chapter-child-item{
          padding: 0 20px;
          height: 56px;
          border-bottom: 1px solid $theme-border-gray;
          cursor: pointer;
          @include flex(row,space-between,center);
          .chapter-child-item-gray-btn{
            @include btn(90px,30px,15px,14px,#E0E0E0,#999999);
          }
          .chapter-child-item-btn{
            @include btn(90px,30px,15px,14px,#333333,#FFFFFF);
          }
          >div:first-child{
            margin-right: 10px;
            flex: 1;
            width: 0;
          }
        }
        .chapter-child-item:hover{
          color: $theme-blue;
          .chapter-child-item-btn{
            @include btn(90px,30px,15px,14px,$theme-blue);
          }
        }
        .active{
          color: $theme-blue;
          .chapter-child-item-btn{
            @include btn(90px,30px,15px,14px,$theme-blue);
          }
        }
      }
    }
  }
  .practice-r{
    margin-left: 40px;
    height: 100%;
    flex: 1;
    width: 0;
    @include flex(column,flex-start,flex-start);
    .practice-r-top{
      @include flex(row,space-between,flex-start);
      width: 100%;
      margin-bottom: 12px;
    }
    .practice-r-tit{
      font-size: 16px;
      font-weight: bold;
    }
    .practice-r-tab{
      @include flex(row,flex-start,center);

      .practice-r-tab-item{
        margin-right: 14px;
        height: 35px;
        >p{
          @include btn(90px,30px,15px,14px,#FAFAFA,#666666);
        }

      }
      .practice-r-tab-item.active{
        background: url("../../../../assets/images/attestation/xz_bj.png");
        background-size: 90px 35px;
        >p{
          background: none;
          color: #FFFFFF;
        }
      }
    }
    .practice-r-cont{
      flex: 1;
      height: 0;
      width: 100%;
    }
    .practice-r-url{
      margin-top: 16px;
      color: #999999;
      .practice-r-url-tit{
        font-weight: bold;
      }
    }
  }
}
</style>
