import { render, staticRenderFns } from "./videoTrain.vue?vue&type=template&id=6247e979&scoped=true&"
import script from "./videoTrain.vue?vue&type=script&lang=js&"
export * from "./videoTrain.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6247e979",
  null
  
)

export default component.exports