<template>
<div class="embedbox" v-if="pptPath">
  <embed  id="iframe" ref="myIframe" :src="pptPath" width="100%" height="100%" type="application/pdf" />
<!--  <div class="embedbox-mask"></div>-->
</div>
</template>

<script>
export default {
  name: "pptCheck",
  props:{
    pptPath:{
      type:String,
      default:''
    }
  },
  watch:{
    pptPath(newV){
      console.log(newV,'newVnewVnewVnewV')
    }
  },
  mounted(){
    window.document.oncontextmenu = function(){
      return false;
    }
  },
  methods:{

  }
}
</script>

<style scoped lang="scss">
.embedbox{
  height: 100%;
  overflow: hidden;
  position: relative;

}
.embedbox embed {
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
  width: 100%;
  height: calc(100% + 50px );
  overflow: hidden;
}
</style>
