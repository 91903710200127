<template>
<div class="exam" ref="container">
  <div class="exam-tit">{{ name }}考试信息</div>
  <div v-if="isPass">
    <div class="exam-cont tc">
      <img :src="certUser.certImg" width="305" height="226" />
    </div>
    <div class="exam-info">
      <div>
        恭喜你！{{ userInfo.realname }}，考试已通过！{{messageList[certUser.cert_status]}}。
      </div>
      <div class="exam-info-btn blue">已通过</div>
    </div>
    <div class="exam-btn" :class="certUser.cert_status == 1 || certUser.cert_status == 4 || certUser.cert_status == 3 ? 'gray' : ''" @click="downCert(certUser)">{{(certUser.cert_status == 1 || certUser.cert_status == 4 || certUser.cert_status == 3) ? certStatus[certUser.cert_status] : '下载证书'}}</div>
    <div class="exam-record" @click="goRecord">
      <p>查看以往考试记录</p>
      <img src="../../../../assets/images/attestation/you.png" width="8" height="10" />
    </div>
  </div>
  <div v-else>
    <div class="exam-cont">
      <div class="exam-cont-tit">考前须知:</div>
      <div class="exam-cont-nr">
        {{ examInfo.notice }}
      </div>
    </div>
    <div class="exam-info">
      <div class="exam-info-nr">
        <p>考试时间：{{ examInfo.exam_timelong }}分钟</p><span>|</span><p>通过条件：{{examInfo.pass_score}}分（满分{{examInfo.all_score}}）</p><span>|</span><p>剩余考试次数：{{certUser.exam_remain_num}} 次</p>
      </div>
      <div class="exam-info-btn" v-if="certUser && certUser.exam_status" :class="certUser.exam_status ==0 || certUser.exam_status ==4 ? '' : 'red'">{{ examStatus[certUser.exam_status] }}</div>
    </div>
    <div class="exam-btn" @click="startBtn" v-if="certUser.exam_status == 0 || certUser.exam_status == 1 ">开始考试</div>
    <div class="exam-btn" @click="goDetail" v-else>重新购买</div>
    <div class="exam-record" @click="goRecord">
      <p>查看以往考试记录</p>
      <img src="../../../../assets/images/attestation/you.png" width="8" height="10" />
    </div>
  </div>
  <el-dialog
      :visible.sync="confirmExamDialog"
      width="310px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
  >
    <div slot="title"></div>

    <div class="confirm-submit-txt">确定开始考试吗？</div>
    <div class="confirm-submit-txt-sub">开始考试后将扣除1次考试次数</div>
    <div slot="footer" class="confirm-submit-txt-btn">
      <template>
        <div @click="cancelExam">先不考了</div>
        <div class="active" @click="startExam">开始考试</div>
      </template>
    </div>
  </el-dialog>
</div>
</template>

<script>
import Watermark from "@/utils/waterMark";
import util from '@/utils/index.js'
export default {
  name: "exam",
  data(){
    return{
      examInfo:{},
      name:'',
      certUser:{},
      userInfo:{},
      certId:'',
      isPass:false,
      showStatus:'',
      showUrl:process.env.VUE_APP_SHOW_URL + '?file=',
      trainUser:{},
      messageList:{
        '1':'证书生成中，请耐心等待5-30分钟',
        '4':'证书生成中，请耐心等待5-30分钟',
        '2':'请点击【下载按钮】下载电子证书',
        '3':'认证已过期'
      },
      confirmExamDialog:false,
      newTrainUser:{},
    }
  },
  props:{
    info:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    examStatus:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    certStatus:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  watch:{
    info:{
      handler(newV){
        if(newV.attr){
          this.examInfo = JSON.parse(JSON.stringify(newV.attr));
          this.name = newV.name;
          this.showStatus = newV.show_status;
          this.certUser = JSON.parse(JSON.stringify(newV.cert_user));

          if(!this.certUser.cert_img.indexOf('http')  == 0){ //不以http开头
            this.certUser.certImg = this.showUrl + this.certUser.cert_img;
          }else{
            this.certUser.certImg = this.certUser.cert_img;
          }


          this.isPass = this.certUser.exam_status == 2;
          this.trainUser = JSON.parse(JSON.stringify(newV.train_user));

        }

      },
      immediate:true,
    },
    examStatus:{
      handler(newV){
        // console.log(newV,'newVnewV')

      },
      immediate:true,
    },
  },
  created(){
    let userInfo = localStorage.getItem('userInfo');
    this.userInfo = userInfo ? JSON.parse(userInfo).user : '';
    this.certId = this.$route.query.id || '';
  },
  mounted(){
    Watermark.set(`${this.userInfo.nickname}(id:${this.userInfo.id})`, this.$refs.container);
  },
  methods:{
    startBtn(){
      let params = {
        action:'checkTrain',
        category_id:this.certId,
        data_type:0,
        data_rule:11
      };
      this.api.attestation.trainExecute(params).then((res)=>{
        let params = {
          action:'trainUserInfo',
          train_id:res.train.id
        };
        this.api.attestation.trainExecute(params).then((res)=>{
          this.newTrainUser = res.train_user;
          if(!res.train_user.start_time || res.train_user.start_time == '0'){
            this.confirmExamDialog = true;
          }else{
            this.$router.push({
              path:'/attestation/exam',
              query:{
                id:this.$route.query.id
              }
            })
          }
        })
      })

      // this.confirmExamDialog = true;
      // this.$router.push({
      //   path:'/attestation/exam',
      //   query:{
      //     id:this.$route.query.id
      //   }
      // })
    },
    startExam(){
      let params = {
        action:'trainUserStart',
        train_user_id:this.newTrainUser.id
      };

      this.api.attestation.trainExecute(params).then((res)=>{
        this.confirmExamDialog = false;
        this.$router.push({
          path:'/attestation/exam',
          query:{
            id:this.$route.query.id
          }
        })
      })

    },
    cancelExam(){
      this.confirmExamDialog = false;
    },
    goRecord(){
      this.$router.push({
        path:'/user/record'
      })
    },
    goDetail(){
      this.$router.push({
        path:'/attestation/detail',
        query:{
          id:this.certId
        }
      })
    },
    downCert(data){
      if(data.cert_status == 2){
        let params = {
          file:data.cert_pdf
        };
        this.api.user.download(params).then((res)=>{
          util.downLoad(res)
        })
      }

    }
    // startExam(){
    //   let params = {
    //     action:'trainUserStart',
    //     train_user_id:this.trainUserId,
    //   };
    //
    //   this.api.attestation.trainExecute(params).then((res)=>{
    //     // this.formatInfoData(res.data_list[0]);
    //     this.attentionModal = false;
    //     // this.getAnswerCard();
    //     this.getCountdownTime();
    //
    //   })
    // }
  }
}
</script>

<style scoped lang="scss">
.exam{
  .exam-tit{
    padding: 60px 0 30px 0;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }
  .exam-cont{
    margin: 0 auto;
    padding: 30px 0;
    width: 1000px;
    background: #FAFBFC;
    border: 1px solid $theme-border-gray;
    .exam-cont-tit{
      margin: 0 40px 20px 40px;
      font-size: 16px;
      font-weight: bold;
    }
    .exam-cont-nr{
      margin-left: 40px;
      line-height: 24px;
      white-space: pre-wrap;
    }
  }
  .exam-info{
    margin-top: 64px;
    @include flex(row,center,center);

    .exam-info-nr{
      @include flex(row,center,center);
      font-weight: bold;
      color: $theme-blue;
      >span{
        padding: 0 10px;
      }
    }
    .exam-info-btn{
      margin-left: 30px;
      padding: 0 5px;
      @include btn(auto,24px,4px,12px,#E0E0E0,#666666);
    }
  }
  .exam-btn{
    margin: 40px auto 18px auto;
    @include btn(240px,48px,24px,16px,linear-gradient(222deg, #3A6CFF, #004FEB));
    font-weight: bold;
  }
  .exam-record{
    @include flex(row,center,center);
    font-weight: bold;
    cursor: pointer;
    >img{
      margin-left: 5px;
    }
  }
  .confirm-submit-txt{
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
  .confirm-submit-txt-sub{
    margin-top: 10px;
    text-align: center;
  }
  .confirm-submit-txt-btn,
  .result-modal-btn{
    @include flex(row,center,center);
    >div{
      margin-right: 14px;
      @include btn(100%,38px,19px,14px,#E1E9FF,#4A79FF);
    }


    .active{
      margin-right: 0;
      @include btn(100%,38px,19px,14px,linear-gradient(222deg, #3A6CFF, #004FEB),#FFFFFF);
    }
  }
}
</style>
