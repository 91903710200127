<template>
<div>
  <video ref="video" id="videoPlayer" :src="videoPath"  class="video-js vjs-default-skin" controls width="100%" controlslist="nodownload">

  </video>
</div>
</template>

<script>
export default {
  name: "videoTrain",
  data(){
    return {
      certId:'',
      type:1,
    }
  },
  props:{
    videoPath:{
      type:String,
      default:''
    },
    certUserId:{
      type:String,
      default:''
    },
    mapId:{
      type:String,
      default:''
    },
    nodeId:{
      type:String,
      default:''
    },
  },
  created(){
    this.certId = this.$route.query.id || '';
  },
  mounted(){
    this.$refs.video.addEventListener('ended',this.updateProgress)
  },
  methods:{
    updateProgress(){
      let params = {
        map_id:this.mapId,
        node_id:this.nodeId,
        cert_id:this.certId,
        type:this.type,
        status:2,
        cert_user_id:this.certUserId
      };
      this.api.attestation.sourceUpdateProgress(params).then((res)=>{
        this.$emit('update')
      })
    }
  },
  beforeDestroy(){
    this.$refs.video.removeEventListener('ended',this.updateProgress)
  }
}
</script>

<style scoped>

</style>
