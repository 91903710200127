<template>
  <div class="word-check">
    <div class="embedbox-tip">
      <div>
        <p>链接地址：{{practical_operation_url}}</p>
<!--        <p>注意：实验操作需跳转火山引擎平台，实操需使用操作账号登录，请注意区分！</p>-->
<!--        <p>{{account && account.account ? `账号：${account.account}，密码：${account.pwd}；点击右侧按钮跳转登录！`:'账号预计1个工作日内分配完成，请耐心等待！'}}</p>-->
      </div>
      <div class="embedbox-tip-r">
        <div class="embedbox-tip-r-btn" @click="goOperation">去练习</div>
        <div class="embedbox-tip-r-op" @click="showOp">
          <img src="../../../../assets/images/common/zhuyi.png" width="16" height="16"/>
          <p>操作指南</p>
        </div>
      </div>

    </div>
    <div class="embedbox" v-if="wordPath">

      <embed class="embedbox-nr"  id="iframe" ref="myIframe" :src="wordPath " width="100%" height="100%" type="application/pdf" />
      <!--  <div class="embedbox-mask"></div>-->
    </div>
    <el-dialog
        title="实操平台操作指南"
        :visible.sync="instruction"
        :show-close="false"
        width="526px"
        @close="closeInstruction"
    >
      <div slot="title" class="instruction-title">
        实操平台操作指南
      </div>
      <div class="instruction">
        <video v-if="instruction" :src="$store.state.ssoOauthNoticeVideo" class="instruction-video" controls width="100%" controlslist="nodownload"></video>
        <div class="instruction-desc">
          <p>请按照以上操作步骤登录实操平台；</p>
          <p>后续查看此操作指南请点击【去练习】后的“操作指南”按钮查看</p>
        </div>
        <div class="instruction-btns">
          <div @click="closeInstruction">取消</div>
          <div class="active" @click="goVolcano">去练习</div>
        </div>
      </div>

    </el-dialog>
  </div>

</template>

<script>
export default {
  name: "pptCheck",
  data(){
    return{
      instruction:false,
      userInfo:{},
    }
  },
  props:{
    wordPath:{
      type:String,
      default:''
    },
    practical_operation_url:{
      type:String,
      default:''
    },
    account:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  watch:{
    wordPath(newV){

    }
  },
  mounted(){
    window.document.oncontextmenu = function(){
      return false;
    }
  },
  methods:{
    goOperation(){
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      let name = this.userInfo.user.id + 'sso_oauth_notice_video';
      if(!localStorage.getItem(name)){
        this.instruction = true;
      }else{
        window.open(this.practical_operation_url,'_blank')
      }
    },
    closeInstruction(){
      this.instruction = false;
    },
    goVolcano(){
      let name = this.userInfo.user.id + 'sso_oauth_notice_video';
      localStorage.setItem(name,'1');
      this.instruction = false;
      window.open(this.practical_operation_url,'_blank')
    },
    showOp(){
      this.instruction = true;
    }
  }
}
</script>

<style scoped lang="scss">
.word-check{
  height: 100%;
  overflow: hidden;
  position: relative;
  @include flex(column,flex-start,flex-start);
  .embedbox-tip{
    margin-bottom: 14px;
    width: 100%;
    font-size: 14px;
    height: 60px;
    line-height: 22px;
    background: #FAFBFC;
    border: 1px dashed #8EABFF;
    box-sizing: border-box;
    border-radius: 10px;
    @include flex(row,space-between,center);
    >div:nth-child(1){
      padding-left: 20px;
    }
    .embedbox-tip-r{
      margin-right: 20px;
      @include flex(row,flex-start,center);
      .embedbox-tip-r-btn{
        @include btn(70px,28px,14px,14px,$theme-blue);
      }
      .embedbox-tip-r-op{
        margin-left: 12px;
        @include flex(row,flex-start,center);
        text-decoration: underline;
        color: $theme-orange-text;
        cursor: pointer;
        >img{
          margin-right: 2px;
        }
      }
    }
  }
  .embedbox{
    flex: 1;
    height: 0;
    width: 100%;
    overflow: hidden;
  }
  .instruction-title{
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
  .instruction{
    margin: 0 auto;
    font-size: 14px;
    line-height: 22px;
    width: 440px;
    .instruction-video{
      margin-bottom: 30px;
      width: 100%;
    }
    .instruction-desc{
      text-align: center;
      >p{
        margin-bottom: 6px;
      }
    }
    .instruction-btns{
      margin-top: 30px;
      @include flex(row,center,center);
      >div{
        @include btn(110px,38px,19px,14px,$theme-light-blue-bg,$theme-blue-text)
      }
      .active{
        margin-left: 14px;
        background: $theme-blue-btn-bj;
        color: $theme-white-text;
      }
    }
  }
}
.embedbox embed {
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);

  width: 100%;
  height: calc(100% + 50px );
  overflow: hidden;
}
</style>
