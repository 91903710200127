<template>
<div class="train" ref="container">
  <div class="train-top contW">
<!--    <myBreadcrumb></myBreadcrumb>-->
<!--    <div class="practice-l-tit">{{ info.name }}</div>-->
  </div>
  <div class="train-cont contW " >
    <div class="tab-list">
      <div class="tab-list-item" :class="curTabIndex == index ? 'active' : ''" v-for="(item,index) in tabList" :key="item.id" @click="changeTab(index)">
        <p>{{ item.name }}</p>
        <div></div>
      </div>
    </div>
    <div class="train-nr">
      <Practice v-show="curTabIndex == 0" :list="treeList" :info="info" @update="update"></Practice>
      <Exam v-show="curTabIndex == 1" :info="info" :examStatus="examStatus" :certStatus="certStatus"></Exam>
    </div>
  </div>
</div>
</template>

<script>
import Practice from "@/views/attestation/components/train/practice";
import Exam from "@/views/attestation/components/train/exam";
import Watermark from "@/utils/waterMark";
export default {
  name: "train",
  data(){
    return {
      tabList:[
        {
          name:'学习练习',
          id:1,
          componentName:'Practice',
          tab:'prac'
        },
        {
          name:'认证考试',
          id:2,
          componentName:'Exam',
          tab:'exam'
        },
      ],
      curTabIndex:0,
      certId:'',
      info:{},
      treeList:[],
      examStatus:{},
      certStatus:{},
    }
  },
  components:{
    Practice,
    Exam
  },
  created(){
    this.certId = this.$route.query.id || '';
    let tabName = this.$route.params.tab || '';
    if(tabName){
      this.curTabIndex = this.tabList.findIndex((item)=>{
        return item.tab == tabName;
      })
    }
  },
  beforeDestroy(){
    document.removeEventListener("contextmenu", this.disableRight);
  },
  mounted(){
    let userInfo = localStorage.getItem('userInfo');
    userInfo = userInfo ? JSON.parse(userInfo).user :{},
    Watermark.set(`${userInfo.nickname}(id:${userInfo.id})`, this.$refs.container);
    let token = localStorage.getItem('token');
    if(token){
      this.getCategoryDetail();
    }else{
      this.getDetail();
    }
    document.addEventListener("contextmenu", this.disableRight);
  },
  methods:{
    disableRight(e){
      e.preventDefault();
    },
    changeTab(index){
      this.curTabIndex = index;
      this.$router.push({
        path:'/attestation/train',
        name:'attestationTrain',
        params:{
          tab:this.tabList[this.curTabIndex].tab,
        },
        query:{
          id:this.certId,
          pos:this.$route.query.pos
        }
      })
    },
    getDetail(){
      let params = {
        id:this.certId,
        with_progress:1
      };
      this.api.attestation.certDetail(params).then((res)=>{
        this.info = res.info;
        this.examStatus= res.exam_statuses;
        this.certStatus= res.cert_status;
        this.treeList = this.info.tree;
        console.log(this.certStatus,'this.certStatus=============')
      })
    },
    getCategoryDetail(){
      let params = {
        id:this.certId,
        with_progress:1
      };
      this.api.attestation.categoryDetail(params).then((res)=>{
        this.info = res.info;
        this.examStatus= res.exam_statuses;
        this.certStatus= res.cert_status;
        this.treeList = this.info.tree;
        console.log(this.treeList,'this.treeList')
      })
    },
    update(){
      this.getCategoryDetail();
    }
  }
}
</script>

<style scoped lang="scss">
.train{
  height: 100%;
  overflow-y: auto;
  .train-top{
    padding-top: 84px;
    .practice-l-tit{
      //padding-top: 46px;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .train-cont{
    //margin-top: 30px;
    height: calc(100% - 112px);
    .train-nr{
      height: calc(100% - 32px);
    }
  }
}
</style>
